body {
    touch-action: none;
    position: fixed;
    /* Prevent elastic scrolling */
    overflow: hidden;
    margin: 0;
    width: 100vw;
    height: 100vh;
    font-family: 'Raleway', 'Roboto', sans-serif;
}

#obsessvr-webstore-react-embed-root {
    height: 100%;
    width: 100%;
}

#app {
    position: absolute;
    margin: 0;
    width: 100%;
    height: 100%;
}

canvas {
    position: absolute;
    z-index: 1;
}

.text-center {
    text-align: center;
}

.shadow {
    box-shadow: 0px 2px 4px 0px #777777;
}

.imageShadow {
    -webkit-filter: drop-shadow(0px 2px 4px #777777);
    filter: drop-shadow(0px 2px 4px #777777);
    transform: translate3d(0, 0, 0);  /* This prevents crazy visual glitches from using drop shadow filter */
}

/* Mall specific loading icon color changes, implemented here to prevent conflict with WSM default colors */
.cssload-ball_1 {
    background-color: #F8D526;
}

.cssload-ball_2 {
    background-color: #FE885E;
}

.cssload-ball_3 {
    background-color: #FA6376;
}

.cssload-ball_4 {
    background-color: #C184D7;
}

@media screen and (max-width: 480px) {
    html.auth0-lock-html body > * {
        display: block !important;
    }

    .auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-body-content {
        background: #fff0 !important;        
    }
}

@media only screen and (max-width: 415px) {
    .auth0-lock.auth0-lock .auth0-lock-cred-pane {
        height: auto !important;
        background: #fff0 !important;
        /* display: table; */
        width: 80%;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
    }

    .auth0-lock.auth0-lock .auth0-lock-header {
        background: #fff0 !important;   
    }

    .auth0-lock-header-bg-solid {
        background-color: #fff0 !important;
    }
}

#disqus_thread {
    bottom: 0;
    z-index: 100;
    position: absolute;
    left: 0;
    width: 100%;
    height: 500px;
    background-image: linear-gradient(to bottom right, #FB737F, #C697D9);
}

#layout {
    padding: 1em;
}

.auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-submit {
    margin: 3em auto;
    padding: 0em;
    font-size: 15px;
    font-weight: bold;
    box-shadow: 0px 2px 4px 0px #777777;
    background-color: #EA5A67 !important;
    color: white;
    border: none;
    font-family: 'Raleway', 'Roboto', sans-serif;
    width: 150px;
    height: 40px;
    margin-top: 1em !important;
}

.auth0-lock.auth0-lock .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon {
    background: #fff;
    opacity: 0.8;
}

.auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-content .auth0-lock-form b {
    color: #FFF;
}

.auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-content .auth0-lock-form p {
    color: #000;
}

.auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-content .auth0-lock-form p span>div {
    background-color: #000;
    transform: skewX(-30deg);
    padding: 0.1em 0.5em;
    width: 5em;
    margin: auto;
}
.auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-content .auth0-lock-form p span>div>div {
    transform: skewX(30deg);
    font-size: 15px;
}

.auth0-lock.auth0-lock .auth0-lock-form .auth0-lock-alternative .auth0-lock-alternative-link {
    color: white;
}

.auth0-lock.auth0-lock a {
    color: black;
}

.auth0-lock.auth0-lock .auth0-lock-submit span svg.icon-text {
    display: none;
}

.auth0-lock.auth0-lock .auth0-lock-submit {
    transition: none !important;
}

.auth0-lock.auth0-lock .auth0-lock-form .auth0-lock-alternative .auth0-lock-alternative-link {
    color: white !important;
}

.auth0-lock.auth0-lock .auth0-lock-back-button {
    display: none;
}

/* HACK to force red color on loading */
.auth0-lock.auth0-lock.auth0-lock-mode-loading .auth0-lock-submit {
    background-color: #EA5A67 !important;
    animation: 1s linear infinite forceYellow;
}

@keyframes forceYellow {
    from {
        background-color: #EA5A67;
    }
    to {
        background-color: #EA5A67;
    }
}

.auth0-lock.auth0-lock .auth0-loading-container .auth0-loading {
    left: 40%;
}

.auth0-lock.auth0-lock.auth0-lock-opened.auth0-lock-iphone .auth0-lock-body-content {
    min-height: calc(100vh - 556px) !important;
}

.auth0-lock.auth0-lock .auth0-lock-header-logo {
    height: 87px !important;
}

.auth0-lock.auth0-lock {
    font-family: 'Raleway', 'Roboto' !important;
}


.enterCodeText {
    font-weight: bold;
    text-decoration: none !important;
}

@media only screen and (max-width: 415px) {
    .auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-content .auth0-lock-form p {
        color: #fff;
    }

    .auth0-lock.auth0-lock a {
        color: #fff;
    }
}