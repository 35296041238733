#splashScreen {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    z-index: 999;
    background-image: linear-gradient(150deg , #FF4864 10%, #B230E7 105%);
    display: flex;
    justify-content: center;
    /* Filter used to brighten gradient */
    filter: brightness(1.05);
}

#splashScreenItemWrapper {
    text-align: center;
    align-self: center;
}

#obsessLogo {
    margin-bottom: 3em;
    width: 15em;
    /* Static height is set to preserve spacing between elements in the splash screen while image is loading */
    min-height: 5.813em;
}

#splashLoadingIcon {
    position: relative;
    margin: 3em auto;
    width: 100%;
}

.coloredWord {
    color: #FBFD04;
}

@media only screen and (max-width: 415px) {
    #splashScreenItemWrapper {
        margin-bottom: 24vh;
    }
}
